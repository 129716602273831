import React, { useState } from "react";
import { addTireToCart } from "../cartFunctions";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import * as StyledComponents from "./addToCartTireButton.module.scss";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
export const AddToCartTireButtonCard = ({
  ximage,
  xsku,
  xbrand,
  xmodel,
  xprice,
  xwidth,
  xaspect,
  xdiam,
  xseason,
}) => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();

  //default value for items count
  const [Count, setCount] = useState(4);

  //change the count
  function changeCount(params) {
    let newCount = Count;
    if (params === "-" && Count !== 1) {
      newCount--;
      setCount(newCount);
    }
    if (params === "+") {
      newCount++;
      setCount(newCount);
    }
  }

  //add product to useContext cart
  function addProduct() {
    //object of new product to add
    let productObject = {
      image: ximage,
      sku: xsku,
      brand: xbrand,
      model: xmodel,
      price: xprice,
      width: xwidth,
      aspect: xaspect,
      diam: xdiam,
      season: xseason,
      total: Count,
    };

    // perform operations and add to LocalStorage
    let newCart = addTireToCart(NewCartContent, productObject);

    //add product to redux cart
    dispatch(allActions.cart.setNewCart(newCart));
    //console.log(NewCartContent.tires);
  }

  return (
    <>
      <div className={StyledComponents.button}>
        <button onClick={() => changeCount("-")}>
          <h6>-</h6>
        </button>
        <h6>{Count}</h6>
        <button onClick={() => changeCount("+")}>
          <h6>+</h6>
        </button>
        <Button
          onClick={() => addProduct(Count)}
          className={StyledComponents.ajouter}
          endIcon={<ShoppingCartIcon />}
          variant="contained"
          fullWidth={true}
          sx={{
            backgroundColor: "var(--darkgreen)",
            borderRadius: "0",
            [`&:hover, &.Mui-focusVisible`]: {
              backgroundColor: "var(--darkgreen)",
            },
          }}
        >
          <p>Ajouter au panier </p>
        </Button>
      </div>
    </>
  );
};
